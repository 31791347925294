import * as React from "react"

const Cta = ({content}) => {
  return (
  <div className='p30 fade--in'>
    <p className='text-center m0 max-550 ma'>
     {content}
    </p>
  </div>
  )
}
export default Cta
