import * as React from "react"

const Introduction = ({content}) => {
  return (
  <div className='introduction p30'>
    <div className='max-750 with-links inner ma text-center h1 white-space' dangerouslySetInnerHTML={{ __html: content }}/>
  </div>
  )
}
export default Introduction
