import React, { useEffect } from 'react'
import Layout from "../components/layout"
import Projects from "../components/projects"
import Introduction from "../components/introduction"
import Spacer from "../components/spacer"
import Cta from "../components/cta"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { gsap } from "gsap"

const IndexPage = ({data}) => {
  var projects = data.allDatoCmsProject;
  var page = data.datoCmsHomepage;
  useEffect(() => {
    gsap.to('.masthead', { opacity:1, duration:.5});
    gsap.to('.introduction .inner', { opacity:1, y:0, ease:"power3.inOut", duration:1, delay:.5}); 
    gsap.to('.all--projects', { opacity:1, y:0, ease:"power3.inOut", duration:1, delay:1});
    setTimeout(function() {
      document.querySelector('.all--projects').classList.add('active')
    }, 1000);
  }); 
  return (
  <Layout>
    <Seo title="Carl Beaverson | Brisbane Web Developer" />
    <Spacer />
    <Introduction content={page.introduction} />
    <Spacer />
    <Projects projects={projects} />
    <Spacer />
    <Cta content={page.bottomCta} />
    <Spacer />
  </Layout>
  )
} 

export default IndexPage

export const datoQuery = graphql`
  query {
    datoCmsHomepage {
      introduction
      bottomCta
    }
    allDatoCmsProject(sort: {fields: position}) {
      nodes {
        title
        image {
          gatsbyImageData
        }
        notes
        url
      }
    }
  }
`