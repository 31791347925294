import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image" 

const Projects = ({ projects }) => {
  projects = projects.nodes
  return (
  <div className='all--projects p30 grid'>
    {projects.map((project, index) => { 
      return (
        <SingleProject project={project} key={index}/>
      )
    })}
  </div>
  )
}
export default Projects

const SingleProject = ({ project }) => {
  
  const shakeProject = (e) => {
    var project = e.target.closest('.will-shake');
    project.classList.add('shake');
    setTimeout(function(){ project.classList.remove('shake'); }, 750);
  } 

  if(project.url){
    return (
      <a href={project.url} target='_blank' rel='noreferrer' className='link'>
        <Internal project={project} />
      </a>
    )
  } else {
    return (
      <div role='presentation' className='will-shake with-offline' onClick={shakeProject}>
        <Internal onClick={shakeProject} project={project} />
      </div>
    )
  }
}
 
const Internal = ({project}) => {
  return (
    <div className='project bg-grey br-5 hover-zoom pointer'>
      <div className='ratio-1-1 pos-rel'>
        <div className='image-container'>
          <div className='w-100 ma inner'>
            <div className='ma image br-5 overflow-hidden'>
              <div className='ratio-2-1 pos-rel image-internal'>
                <GatsbyImage image={project.image?.gatsbyImageData} alt='Gallery Image' className="bg-image" />
              </div>
            </div> 
          </div>
        </div>
      </div>
      <div className='information p20 pt0 flex'>
        {(project.url ?
          <p className='m0 pos-rel'>{project.title}</p> :
          <p className='m0 pos-rel'><span className='title'>{project.title}</span><span className='offline'>Currently Offline</span></p>

        )}
        <p className='m0 mla notes'>{project.notes}</p>
      </div>
    </div>
  )
}